import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from "gatsby-image"
import { Col, Container, Row, Card} from "react-bootstrap"
import styled from 'styled-components'

const BlogContainer = styled(Container)`
  margin: 2rem 0;
`

const Blog = () => {
    const data = useStaticQuery(graphql`
      query BlogComponentQuery {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(posts)/"  }}) {
            edges {
                node {
                  fields {
                    slug
                    }
                  frontmatter {
                      title
                      date
                      description
                      image {
                          id
                          childImageSharp {
                            fluid(maxWidth: 700, quality: 100) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                      }
                      rawMarkdownBody
                  }
            }
        }
      }
    `)
  
    const blogData = data.allMarkdownRemark.edges

    return (
            <BlogContainer>
              <Row>
                {blogData.map(({ node }) => {
                  const slug = "blog/" + node.fields.slug
                    return (
                        <Col md={4} key={slug}>
                          <Card>
                            <Link to={slug}>
                                {node.frontmatter.image ? <Img fluid={node.frontmatter.image.childImageSharp.fluid} alt={node.frontmatter.name} /> : ''}
                                <Card.Body>
                                <h5>{node.frontmatter.title}</h5>
                                <p className='date'>{node.frontmatter.date}</p>
                                <p>{node.frontmatter.description}</p>
                                </Card.Body>
                            </Link>
                          </Card>
                        </Col>
                    )
                })}
              </Row>
            </BlogContainer>
    )
  }
  
  export default Blog