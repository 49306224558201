import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Blog from "../components/blog"

const BlogPage = () => (
  <Layout pageInfo={{ pageName: "blog" }}>
    <SEO title="Blog" />
    <h1>Blog</h1>
    <Blog />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default BlogPage
